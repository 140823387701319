import { OktaAuth } from "@okta/okta-auth-js";

export interface AccessToken {
  expiresOnTimestamp: number;
  token: string;
}

export class TokenCredential {
  constructor(readonly oktaAuth: OktaAuth) {}

  async getToken(
    scopes: string | string[],
    options?: object,
  ): Promise<AccessToken | null> {
    scopes;
    options;

    const authState = this.oktaAuth.authStateManager.getAuthState();
    if (authState) {
      if (authState.isAuthenticated) {
        return {
          token: authState.accessToken.accessToken,
          expiresOnTimestamp: authState.accessToken.expiresAt,
        };
      }
    }

    return null;
  }
}

import React from "react";

export interface WarningMessageProps {
  response: any;
}

export default class WarningMessage extends React.Component<WarningMessageProps> {
  render(): React.ReactNode {
    return <div>Warning: {this.props.response.bodyAsText}</div>;
  }
}

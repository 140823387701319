import React from "react";
import { CircularProgress } from "@bb-ui/react-library/dist/components/Progress";
import { Grid } from "@bb-ui/react-library/node_modules/@material-ui/core";

export interface LoadingProps {
  readonly render: boolean;
  readonly msg?: string;
}

export default class Loading extends React.Component<LoadingProps> {
  render(): React.ReactNode {
    if (!this.props.render) return null;

    const msg = this.props.msg || "Loading...";

    return (
      <Grid container justify="center" style={{ margin: "20px" }}>
        <CircularProgress label={msg} size="large" css="" />
      </Grid>
    );
  }
}

import React from "react";
import { Paper } from "@bb-ui/react-library/dist/components/Paper";
import { Typography } from "@bb-ui/react-library/dist/components/Typography";
import { BbTmpPrivEscClient } from "../../client/src";
import EscalationsTable from "./escalations-table";

export interface GroupEscalationsViewProps {
  readonly group: string;
  readonly apiClient: BbTmpPrivEscClient;
}

export default class GroupEscalationsView extends React.Component<GroupEscalationsViewProps> {
  render(): React.ReactNode {
    return (
      <Paper css="" style={{ padding: "10px" }}>
        <Typography variant="h1" gutterBottom css="">
          Group - {this.props.group}
        </Typography>

        <Typography variant="body1" gutterBottom css="">
          Sorted by latest start time.
        </Typography>

        <EscalationsTable
          group={this.props.group}
          apiClient={this.props.apiClient}
        />
      </Paper>
    );
  }
}

import * as React from "react";
import { Link } from "react-router-dom";
import { withOktaAuth } from "@okta/okta-react";
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";
import { Paper } from "@bb-ui/react-library/dist/components/Paper";
import { Typography } from "@bb-ui/react-library/dist/components/Typography";
import { Divider } from "@bb-ui/react-library/dist/components/Divider";
import { Badge } from "@bb-ui/react-library/dist/components/Badge";
import { List } from "@bb-ui/react-library/dist/components/List";
import { ListItem } from "@bb-ui/react-library/dist/components/ListItem";
import Loading from "./loading";

export interface HomeProps extends IOktaContext {
  readonly oktaTenant: string;
  readonly slackChannel: string;
  readonly slackLink: string;
}

class Home extends React.Component<HomeProps, {}> {
  constructor(props: HomeProps) {
    super(props);
  }

  render() {
    const loading = <Loading render />;
    if (this.props.authState) {
      if (!this.props.authState.isAuthenticated) {
        return loading;
      }
    } else {
      return loading;
    }

    const groupsClaim: string[] =
      this.props.authState.accessToken.claims.groups || [];

    groupsClaim.sort();

    const groups = groupsClaim.map((g) => {
      return <ListItem css="">{g}</ListItem>;
    });

    return (
      <Paper css="" style={{ padding: "20px" }}>
        <Typography variant="body1" gutterBottom css="">
          This application is for Blackboard/Anthology employees only. It allows
          employees to schedule and review escalation requests to privileged
          groups within the <b>{this.props.oktaTenant}</b> Okta tenant.
        </Typography>

        <br />
        <Divider variant="middle" css="" />
        <br />

        <Typography variant="h1" gutterBottom css="">
          Group Escalation Requests
        </Typography>
        <Typography variant="body1" gutterBottom css="">
          An escalation is a request for temporary membership to a privileged
          Okta group that has elevated permissions typically not required for
          day to day work/operations in downstream tooling. For example, admin
          level permissions in production AWS accounts. Privileged group names
          are suffixed with <b>-escalated</b>, escalations can be created for
          these groups only. Click the link below to create a new escalation.
          <br />
          <br />
          <Link to="/create-escalation">Create an Escalation</Link>
        </Typography>

        <br />
        <Divider variant="middle" css="" />
        <br />

        <Typography variant="h1" gutterBottom css="">
          Your Okta ID
        </Typography>
        <Typography variant="body1" gutterBottom css="">
          <b>{this.props.authState.accessToken.claims.uid}</b>
        </Typography>

        <br />
        <Divider variant="middle" css="" />
        <br />

        <Typography variant="h1" gutterBottom css="">
          <Badge badgeContent={groups.length} position="inline" css="">
            Your Groups
          </Badge>
        </Typography>
        <Typography variant="body1" gutterBottom css="">
          Groups you are a member of in the Okta tenant are listed below. Note
          that if you have recently been escalated to a new group through this
          system it will not appear on this page until the authentication tokens
          have been refreshed, you can logout and then back in to Okta then
          navigate back to this page to verify.
        </Typography>
        <List css="">
          <b>{groups}</b>
        </List>

        <br />
        <Divider variant="middle" css="" />
        <br />

        <Typography variant="h1" gutterBottom css="">
          Notifications
        </Typography>

        <Typography variant="body1" gutterBottom css="">
          All privilege escalation requests are sent to the{" "}
          <a href={this.props.slackLink} target="_blank">
            {this.props.slackChannel}
          </a>{" "}
          channel. Escalation requestors and the designated reviewers will be
          tagged. Please join the channel and invite reviewers if you would like
          to utilize this functionality to further discuss escalation requests.
        </Typography>

        <br />
        <Divider variant="middle" css="" />
        <br />

        <Typography variant="h1" gutterBottom css="">
          Escalation Schedule
        </Typography>

        <Typography variant="body1" gutterBottom css="">
          <Link to="/escalation-schedule">View Escalation Schedule</Link>
        </Typography>
      </Paper>
    );
  }
}

export default withOktaAuth(Home);

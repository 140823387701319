import React from "react";
import { Paper } from "@bb-ui/react-library/dist/components/Paper";
import { Typography } from "@bb-ui/react-library/dist/components/Typography";
import { BbTmpPrivEscClient } from "../../client/src";
import ReviewForm from "./review-form";
import ReviewsTable from "./reviews-table";
import EscalationDisplay from "./escalation-display";

export interface EscalationViewProps {
  readonly group: string;
  readonly id: string;
  readonly apiClient: BbTmpPrivEscClient;
}

interface EscalationViewState {
  loading: boolean;
  escalationDisplayRefreshKey: string;
  reviewsTableRefreshKey: string;
}

export default class EscalationView extends React.Component<
  EscalationViewProps,
  EscalationViewState
> {
  state: EscalationViewState = {
    loading: true,
    escalationDisplayRefreshKey: "esc-start",
    reviewsTableRefreshKey: "rev-start",
  };

  constructor(props: EscalationViewProps) {
    super(props);
    this.handleReviewCreate = this.handleReviewCreate.bind(this);
  }

  render(): React.ReactNode {
    return (
      <Paper css="" style={{ padding: "10px" }}>
        <Typography variant="h1" gutterBottom css="">
          Escalation - {this.props.id}
        </Typography>

        <EscalationDisplay
          key={this.state.escalationDisplayRefreshKey}
          group={this.props.group}
          id={this.props.id}
          apiClient={this.props.apiClient}
        />

        <Typography variant="h2" css="">
          Review Escalation
        </Typography>
        <ReviewForm
          group={this.props.group}
          escalation={this.props.id}
          onSuccess={this.handleReviewCreate}
          apiClient={this.props.apiClient}
        />

        <Typography variant="h2" css="">
          Reviews
        </Typography>
        <ReviewsTable
          key={this.state.reviewsTableRefreshKey}
          group={this.props.group}
          escalation={this.props.id}
          apiClient={this.props.apiClient}
        />
      </Paper>
    );
  }

  handleReviewCreate = async () => {
    this.setState({
      escalationDisplayRefreshKey: "esc-rev-created",
      reviewsTableRefreshKey: "rev-created",
    });
  };
}

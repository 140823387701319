import React from "react";
import TablePagination from "@material-ui/core/TablePagination";
import { Table } from "@bb-ui/react-library/dist/components/Table";
import { TableHead } from "@bb-ui/react-library/dist/components/TableHead";
import { TableBody } from "@bb-ui/react-library/dist/components/TableBody";
import { TableRow } from "@bb-ui/react-library/dist/components/TableRow";
import { TableCell } from "@bb-ui/react-library/dist/components/TableCell";
import { Review } from "../crud/models/review";
import { HttpUtils, TimeUtils } from "./ui-utils";
import { BbTmpPrivEscClient } from "../../client/src";
import Loading from "./loading";
import NotifBanner from "./notif-banner";
import WarningMessage from "./warning-message";

export interface ReviewsTableProps {
  readonly group: string;
  readonly escalation: string;
  readonly apiClient: BbTmpPrivEscClient;
}

interface ReviewsTableState {
  loading: boolean;
  reviews: Review[];
  currentPage: number;
  pageSize: number;
  nextPageToken?: string;
  warn?: React.JSX.Element;
  error?: any;
}

export default class ReviewsTable extends React.Component<
  ReviewsTableProps,
  ReviewsTableState
> {
  state: ReviewsTableState = {
    loading: true,
    reviews: [],
    pageSize: 10,
    currentPage: 0,
  };

  private readonly abortController = new AbortController();
  private readonly abortSignal = this.abortController.signal;

  constructor(props: ReviewsTableProps) {
    super(props);
    this.pageChange = this.pageChange.bind(this);
    this.rowsPerPageChange = this.rowsPerPageChange.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.getPage(this.state.currentPage, this.state.pageSize, undefined);
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  async getPage(
    pageNumber: number,
    pageSize: number,
    pageToken?: string,
  ): Promise<void> {
    this.setState({
      loading: true,
    });

    try {
      await this.props.apiClient.listReviews(
        this.props.group,
        this.props.escalation,
        {
          pageSize: pageSize,
          pageToken: pageToken,
          abortSignal: this.abortSignal,
          onResponse: (resp) => {
            if (HttpUtils.is4xxStatusCode(resp.status)) {
              console.warn(resp);
              this.setState({
                loading: false,
                warn: <WarningMessage response={resp} />,
              });
              return;
            }

            this.setState({
              loading: false,
              pageSize: pageSize,
              currentPage: pageNumber,
              nextPageToken: resp.parsedBody.nextPageToken,
              reviews: resp.parsedBody.reviews as unknown as Review[],
            });
          },
        },
      );
    } catch (error) {
      console.error(error);

      if (this.abortSignal.aborted) return;

      this.setState({
        loading: false,
        error,
      });
    }
  }

  render(): React.ReactNode {
    if (this.state.loading) return <Loading render msg="Loading reviews..." />;

    if (this.state.warn || this.state.error)
      return <NotifBanner warn={this.state.warn} error={this.state.error} />;

    const rows = this.state.reviews.map((r) => {
      return (
        <TableRow key={r.id} css="">
          <TableCell css="">{r.id}</TableCell>
          <TableCell css="">{r.group}</TableCell>
          <TableCell css="">{r.status}</TableCell>
          <TableCell css="">{r.user}</TableCell>
          <TableCell css="">{TimeUtils.isoToLocale(r.createTime)}</TableCell>
          <TableCell css="">{r.reason}</TableCell>
        </TableRow>
      );
    });

    const nextDisabled = this.state.nextPageToken === undefined ? true : false;

    return (
      <div>
        <Table css="">
          <TableHead css="">
            <TableRow css="">
              <TableCell css="">ID</TableCell>
              <TableCell css="">Group</TableCell>
              <TableCell css="">Status</TableCell>
              <TableCell css="">Reviewer</TableCell>
              <TableCell css="">Created</TableCell>
              <TableCell css="">Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody css="">{rows}</TableBody>
        </Table>

        <TablePagination
          component="div"
          count={-1}
          page={this.state.currentPage}
          onPageChange={this.pageChange}
          rowsPerPage={this.state.pageSize}
          onRowsPerPageChange={this.rowsPerPageChange}
          rowsPerPageOptions={[10, 25, 50]}
          labelDisplayedRows={() => ""}
          backIconButtonText="Back to start"
          nextIconButtonProps={{ disabled: nextDisabled }}
        />
      </div>
    );
  }

  pageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    if (newPage > this.state.currentPage) {
      this.getPage(newPage, this.state.pageSize, this.state.nextPageToken);
    } else {
      this.getPage(0, this.state.pageSize, undefined); // hack: go all the way back to the beginning, could not find a way to store/ref previous nextPageToken via state
    }
  };

  rowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const pageSize = parseInt(event.target.value, 10);
    this.getPage(0, pageSize, undefined);
  };
}

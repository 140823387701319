import React from "react";
import { Group } from "@okta/okta-sdk-nodejs";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { Paper } from "@bb-ui/react-library/dist/components/Paper";
import { Typography } from "@bb-ui/react-library/dist/components/Typography";
import { TextField } from "@bb-ui/react-library/dist/components/TextField";
import { PrimaryButton } from "@bb-ui/react-library/dist/components/Button";
import { Slider } from "@bb-ui/react-library/dist/components/Slider";
import { HttpUtils, LinkUtils } from "./ui-utils";
import { BbTmpPrivEscClient } from "../../client/src";
import NotifBanner from "./notif-banner";
import Loading from "./loading";
import WarningMessage from "./warning-message";
import GroupSearchBox from "./group-search-box";

export interface EscalationFormProps {
  readonly apiClient: BbTmpPrivEscClient;
}

interface EscalationFormState {
  durationHours: number;
  loading: boolean;
  escalatedGroups: Group[];
  success?: React.JSX.Element;
  warn?: React.JSX.Element;
  error?: any;
}

export default class EscalationForm extends React.Component<
  EscalationFormProps,
  EscalationFormState
> {
  state: EscalationFormState = {
    durationHours: 1,
    loading: false,
    escalatedGroups: [],
  };

  readonly durationHoursMin = 1;
  readonly durationHoursMax = 8;

  private readonly abortController = new AbortController();
  private readonly abortSignal = this.abortController.signal;

  constructor(props: EscalationFormProps) {
    super(props);
    this.groupInputChange = this.groupInputChange.bind(this);
    this.sliderChange = this.sliderChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.getEscalatedGroups();
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  async getEscalatedGroups(nameStartsWith?: string): Promise<void> {
    if (nameStartsWith === "") return;

    try {
      const resp = await this.props.apiClient.listGroups({
        abortSignal: this.abortSignal,
        nameStartsWith: nameStartsWith,
      });

      this.setState({ escalatedGroups: resp.groups as unknown as Group[] });
    } catch (error) {
      console.error(error);

      this.setState({ escalatedGroups: [] });
    }
  }

  render(): React.ReactNode {
    let formDisabled = false;
    if (this.state.success || this.state.loading) formDisabled = true;

    return (
      <Paper css="" style={{ padding: "20px" }}>
        <Typography variant="h1" gutterBottom css="">
          Create an Escalation
        </Typography>

        <form onSubmit={this.submit}>
          <GroupSearchBox
            disabled={formDisabled}
            escalatedGroups={this.state.escalatedGroups}
            groupInputChange={this.groupInputChange}
          />

          <TextField
            id="startTime"
            label="Start Time"
            type="datetime-local"
            required
            style={{ marginTop: "10px" }}
            disabled={formDisabled}
            css=""
            defaultValue={DateTime.now().toFormat("yyyy-LL-dd'T'HH:mm")}
          />

          <br />
          <br />

          <Typography component="label" variant="h4" css="">
            Duration (Hours)
          </Typography>
          <Slider
            id="durationHours"
            defaultValue={1}
            step={1}
            marks
            min={this.durationHoursMin}
            max={this.durationHoursMax}
            valueLabelDisplay="auto"
            aria-labelledby="discrete-slider"
            onChange={this.sliderChange}
            disabled={formDisabled}
            css=""
          />

          <TextField
            id="justification"
            label="Justification"
            helperText="limit: 2000 characters, example: I need temporary access to 'bb-example-escalated' because I have a legitimate reason."
            multiline
            rows={6}
            fullWidth
            required
            style={{ marginTop: "10px" }}
            disabled={formDisabled}
            css=""
          />

          <PrimaryButton
            style={{ marginTop: "10px" }}
            type="submit"
            disabled={formDisabled}
            css=""
          >
            Submit
          </PrimaryButton>
        </form>

        <Loading render={this.state.loading} />

        <NotifBanner
          success={this.state.success}
          warn={this.state.warn}
          error={this.state.error}
        />
      </Paper>
    );
  }

  sliderChange = (
    e: React.ChangeEvent<{}>,
    newDurationHours: number | number[],
  ) => {
    const d = newDurationHours as number; // cast cheating as Slider creators do not know how to type properly
    if (Number.isInteger(d)) {
      this.setState({
        durationHours: d,
      });
    }
  };

  groupInputChange = (e: React.ChangeEvent<{}>, newGroupSearch: string) => {
    this.getEscalatedGroups(newGroupSearch);
  };

  submit = async (e: React.FormEvent) => {
    e.preventDefault();

    this.setState({ loading: true, error: undefined, warn: undefined });

    const target = e.target as typeof e.target & {
      group: { value: string };
      startTime: { value: string };
      justification: { value: string };
    };

    const d = DateTime.fromISO(target.startTime.value);

    try {
      await this.props.apiClient.createEscalation(target.group.value, {
        body: {
          startTime: d.toJSDate(),
          durationHours: this.state.durationHours,
          justification: target.justification.value,
        },
        abortSignal: this.abortSignal,
        onResponse: (resp) => {
          if (HttpUtils.is4xxStatusCode(resp.status)) {
            console.warn(resp);
            this.setState({
              loading: false,
              warn: <WarningMessage response={resp} />,
            });
            return;
          }

          const eLink = LinkUtils.escalationLink(
            resp.parsedBody.group,
            resp.parsedBody.id,
          );

          this.setState({
            loading: false,
            success: (
              <div>
                Escalation <Link to={eLink}>{resp.parsedBody.id}</Link> created.
              </div>
            ),
          });
        },
      });
    } catch (error) {
      console.error(error);

      if (this.abortSignal.aborted) return;

      this.setState({
        loading: false,
        error,
      });
    }
  };
}

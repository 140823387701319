import * as React from "react";
import * as ReactDom from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { UiConfig } from "../config";
import App from "./app";

declare const CONFIG: UiConfig;

ReactDom.render(
  <BrowserRouter>
    <App config={CONFIG} />
  </BrowserRouter>,
  document.getElementById("root"),
);

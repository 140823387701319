import * as React from "react";
import { DateTime } from "luxon";
import { MenuItem } from "@bb-ui/react-library/dist/components/MenuItem";
import { Paper } from "@bb-ui/react-library/dist/components/Paper";
import { Typography } from "@bb-ui/react-library/dist/components/Typography";
import { TextField } from "@bb-ui/react-library/dist/components/TextField";
import { BbTmpPrivEscClient } from "../../client/src";
import { EscalationStatus } from "../crud/models/escalation-status";
import { TimeUtils } from "./ui-utils";
import EscalationsTable from "./escalations-table";

export interface EscalationScheduleProps {
  readonly apiClient: BbTmpPrivEscClient;
}

interface EscalationScheduleState {
  escalationStatus: string;
  escStartTimeRangeBegin: string;
  escStartTimeRangeEnd: string;
}

export default class EscalationSchedule extends React.Component<
  EscalationScheduleProps,
  EscalationScheduleState
> {
  state: EscalationScheduleState = {
    escalationStatus: EscalationStatus.Approved,
    escStartTimeRangeBegin: DateTime.now().minus({ days: 7 }).toUTC().toISO(),
    escStartTimeRangeEnd: DateTime.now().plus({ days: 7 }).toUTC().toISO(),
  };

  constructor(props: EscalationScheduleProps) {
    super(props);
    this.escalationStatusChange = this.escalationStatusChange.bind(this);
    this.escStartTimeRangeBeginChange =
      this.escStartTimeRangeBeginChange.bind(this);
  }

  render() {
    const escStatuses = Object.values(EscalationStatus).map((s) => {
      return (
        <MenuItem key={s} value={s} css="">
          {s}
        </MenuItem>
      );
    });

    const startTimeRange = `${this.state.escStartTimeRangeBegin} - ${this.state.escStartTimeRangeEnd}`;

    const escTableKey = `${
      this.state.escalationStatus
    }-${startTimeRange.replace(/\s/g, "")}`;

    const startTimeBeginLabel = `Start Time After (${TimeUtils.isoToLocale(
      this.state.escStartTimeRangeBegin,
    )})`;
    const startTimeEndLabel = `Start Time Before (${TimeUtils.isoToLocale(
      this.state.escStartTimeRangeEnd,
    )})`;

    return (
      <Paper css="" style={{ padding: "20px" }}>
        <Typography variant="h1" gutterBottom css="">
          Escalation Schedule
        </Typography>

        <Typography variant="body1" gutterBottom css="">
          Sorted by latest start time, default start time range returned is 7
          days in the past to 7 days in the future from now.
        </Typography>

        <TextField
          style={{ minWidth: "160px", marginBottom: "10px" }}
          select
          id="home-esc-status-select"
          label="Status"
          value={this.state.escalationStatus}
          onChange={this.escalationStatusChange}
          css=""
        >
          {escStatuses}
        </TextField>

        <TextField
          id="startTimeRangeBegin"
          label={startTimeBeginLabel}
          type="datetime-local"
          style={{ marginLeft: "10px", marginBottom: "10px" }}
          onChange={this.escStartTimeRangeBeginChange}
          css=""
        />

        <TextField
          id="startTimeRangeEnd"
          label={startTimeEndLabel}
          type="datetime-local"
          style={{ marginLeft: "10px", marginBottom: "10px" }}
          onChange={this.escStartTimeRangeEndChange}
          css=""
        />

        <EscalationsTable
          key={escTableKey}
          group="-"
          status={this.state.escalationStatus}
          startTimeRange={startTimeRange}
          apiClient={this.props.apiClient}
        />
      </Paper>
    );
  }

  escalationStatusChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const st = e.target.value as string;
    this.setState({
      escalationStatus: st,
    });
  };

  escStartTimeRangeBeginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const t = DateTime.fromISO(e.target.value).toUTC().toISO();
    this.setState({
      escStartTimeRangeBegin: t,
    });
  };

  escStartTimeRangeEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const t = DateTime.fromISO(e.target.value).toUTC().toISO();
    this.setState({
      escStartTimeRangeEnd: t,
    });
  };
}

import React from "react";
import { Link } from "react-router-dom";
import { AppBar } from "@bb-ui/react-library/dist/components/AppBar";
import { Toolbar } from "@bb-ui/react-library/dist/components/Toolbar";
import { Tooltip } from "@bb-ui/react-library/dist/components/Tooltip";
import { Typography } from "@bb-ui/react-library/dist/components/Typography";
import { PrimaryButton } from "@bb-ui/react-library/dist/components/Button";
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";
import { withOktaAuth } from "@okta/okta-react";
import Loading from "./loading";

export interface NavProps extends IOktaContext {
  postLogoutRedirectUri: string;
}

class Nav extends React.Component<NavProps> {
  constructor(props: NavProps) {
    super(props);
    this.logout = this.logout.bind(this);
  }

  render(): React.ReactNode {
    const loading = <Loading render />;
    if (this.props.authState) {
      if (!this.props.authState.isAuthenticated) {
        return loading;
      }
    } else {
      return loading;
    }

    return (
      <AppBar position="relative" css="" color="primary">
        <Toolbar css="">
          <Typography variant="h1" css="" style={{ flex: 1 }}>
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              Blackboard Privilege Escalation
            </Link>
          </Typography>

          <Tooltip title="Click to logout">
            <PrimaryButton css="" onClick={this.logout}>
              {this.props.authState.accessToken.claims.sub}
            </PrimaryButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    );
  }

  async logout(e: React.MouseEvent<HTMLButtonElement>) {
    this.props.oktaAuth.signOut({
      postLogoutRedirectUri: this.props.postLogoutRedirectUri,
    });
  }
}

export default withOktaAuth(Nav);

import React from "react";
import TablePagination from "@material-ui/core/TablePagination";
import { Link } from "react-router-dom";
import { Table } from "@bb-ui/react-library/dist/components/Table";
import { TableHead } from "@bb-ui/react-library/dist/components/TableHead";
import { TableBody } from "@bb-ui/react-library/dist/components/TableBody";
import { TableRow } from "@bb-ui/react-library/dist/components/TableRow";
import { TableCell } from "@bb-ui/react-library/dist/components/TableCell";
import { Escalation } from "../crud/models/escalation";
import { BbTmpPrivEscClient } from "../../client/src";
import { TimeUtils, LinkUtils, HttpUtils } from "./ui-utils";
import { LogicUtils } from "../shared/utils-shared";
import Loading from "./loading";
import NotifBanner from "./notif-banner";
import WarningMessage from "./warning-message";

export interface EscalationsTableProps {
  readonly group: string;
  readonly apiClient: BbTmpPrivEscClient;
  readonly status?: string;
  readonly startTimeRange?: string;
}

interface EscalationsTableState {
  loading: boolean;
  escalations: Escalation[];
  currentPage: number;
  pageSize: number;
  nextPageToken?: string;
  warn?: JSX.Element;
  error?: any;
}

export default class EscalationsTable extends React.Component<
  EscalationsTableProps,
  EscalationsTableState
> {
  state: EscalationsTableState = {
    loading: true,
    escalations: [],
    pageSize: 10,
    currentPage: 0,
  };

  private readonly abortController = new AbortController();
  private readonly abortSignal = this.abortController.signal;

  constructor(props: EscalationsTableProps) {
    super(props);
    this.pageChange = this.pageChange.bind(this);
    this.rowsPerPageChange = this.rowsPerPageChange.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.getPage(this.state.currentPage, this.state.pageSize, undefined);
  }

  componentWillUnmount(): void {
    // since API client has retries + exponential backoff this should send the signal to cancel retries when the component is diposed of
    this.abortController.abort();
  }

  async getPage(
    pageNumber: number,
    pageSize: number,
    pageToken?: string,
  ): Promise<void> {
    this.setState({
      loading: true,
    });

    try {
      await this.props.apiClient.listEscalations(this.props.group, {
        pageSize: pageSize,
        pageToken: pageToken,
        status: this.props.status,
        startTimeRange: this.props.startTimeRange,
        abortSignal: this.abortSignal,
        onResponse: (resp) => {
          if (HttpUtils.is4xxStatusCode(resp.status)) {
            console.warn(resp);
            this.setState({
              loading: false,
              warn: <WarningMessage response={resp} />,
            });
            return;
          }

          this.setState({
            loading: false,
            pageSize: pageSize,
            currentPage: pageNumber,
            nextPageToken: resp.parsedBody.nextPageToken,
            escalations: resp.parsedBody.escalations,
          });
        },
      });
    } catch (error) {
      console.error(error);

      // abort throws an error on a componentWillUnmount scenario with retries/requests in flight
      // we do not want to render/change state as that would leak memory
      if (this.abortSignal.aborted) return;

      this.setState({
        loading: false,
        error,
      });
    }
  }

  render(): React.ReactNode {
    if (this.state.loading)
      return <Loading render msg="Loading escalations..." />;

    if (this.state.warn || this.state.error)
      return <NotifBanner warn={this.state.warn} error={this.state.error} />;

    const rows = this.state.escalations.map((e) => {
      const escLinkPath = LinkUtils.escalationLink(e.group, e.id);
      const groupLinkPath = LinkUtils.groupLink(e.group);

      return (
        <TableRow key={e.id} css="">
          <TableCell css="">
            <Link to={escLinkPath}>{e.id}</Link>
          </TableCell>
          <TableCell css="">
            <Link to={groupLinkPath}>{e.group}</Link>
          </TableCell>
          <TableCell css="">{e.status}</TableCell>
          <TableCell css="">{e.user}</TableCell>
          <TableCell css="">
            {LogicUtils.ownersGroupFromPrivGroup(e.group)}
          </TableCell>
          <TableCell css="">{TimeUtils.isoToLocale(e.startTime)}</TableCell>
          <TableCell css="">{TimeUtils.isoToLocale(e.endTime)}</TableCell>
          <TableCell css="">{e.justification}</TableCell>
        </TableRow>
      );
    });

    const nextDisabled = this.state.nextPageToken === undefined ? true : false;

    return (
      <div>
        <Table css="">
          <TableHead css="">
            <TableRow css="">
              <TableCell css="">ID</TableCell>
              <TableCell css="">Group</TableCell>
              <TableCell css="">Status</TableCell>
              <TableCell css="">Requestor</TableCell>
              <TableCell css="">Approving Group</TableCell>
              <TableCell css="">Start Time</TableCell>
              <TableCell css="">End Time</TableCell>
              <TableCell css="">Justification</TableCell>
            </TableRow>
          </TableHead>
          <TableBody css="">{rows}</TableBody>
        </Table>

        <TablePagination
          component="div"
          count={-1}
          page={this.state.currentPage}
          onPageChange={this.pageChange}
          rowsPerPage={this.state.pageSize}
          onRowsPerPageChange={this.rowsPerPageChange}
          rowsPerPageOptions={[10, 25, 50]}
          labelDisplayedRows={() => ""}
          backIconButtonText="Back to start"
          nextIconButtonProps={{ disabled: nextDisabled }}
        />
      </div>
    );
  }

  pageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    if (newPage > this.state.currentPage) {
      this.getPage(newPage, this.state.pageSize, this.state.nextPageToken);
    } else {
      this.getPage(0, this.state.pageSize, undefined); // hack: go all the way back to the beginning, could not find a way to store/ref previous nextPageToken via state
    }
  };

  rowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const pageSize = parseInt(event.target.value, 10);
    this.getPage(0, pageSize, undefined);
  };
}

import React from "react";
import debounce from "lodash/debounce";
import { makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@bb-ui/react-library/dist/components/TextField";
import { Group } from "@okta/okta-sdk-nodejs";

const useStyles = makeStyles({
  option: {
    '&[data-focus="true"]': {
      backgroundColor: "lightblue",
    },
  },
});

export interface GroupSearchBoxProps {
  readonly escalatedGroups: Group[];
  readonly disabled: boolean;
  readonly groupInputChange: (
    e: React.ChangeEvent<{}>,
    newGroupSearch: string,
  ) => void;
}

export default function GroupSearchBox(props: GroupSearchBoxProps) {
  const classes = useStyles();

  return (
    <Autocomplete
      id="group"
      classes={{ option: classes.option }}
      options={props.escalatedGroups}
      getOptionLabel={(option) => option.profile.name}
      onInputChange={debounce(props.groupInputChange, 700)}
      noOptionsText="No groups found yet. Group search operates on a group name starts with basis. Exact group names can still be used if they are known."
      renderInput={(params) => (
        <TextField
          {...params}
          label="Group Name"
          helperText="example: bb-example-escalated"
          required
          fullWidth
          disabled={props.disabled}
          css=""
        />
      )}
    />
  );
}

import * as coreClient from "@azure/core-client";
import * as coreRestPipeline from "@azure/core-rest-pipeline";
import * as coreAuth from "@azure/core-auth";
import * as Parameters from "./models/parameters";
import * as Mappers from "./models/mappers";
import {
  HostOptions,
  BbTmpPrivEscClientOptionalParams,
  ListGroupsOptionalParams,
  ListGroupsResponse,
  ListEscalationsOptionalParams,
  ListEscalationsResponse,
  CreateEscalationOptionalParams,
  CreateEscalationResponse,
  GetEscalationOptionalParams,
  GetEscalationResponse,
  ListReviewsOptionalParams,
  ListReviewsResponse,
  CreateReviewOptionalParams,
  CreateReviewResponse,
  GetReviewOptionalParams,
  GetReviewResponse,
} from "./models";

export class BbTmpPrivEscClient extends coreClient.ServiceClient {
  $host: HostOptions;

  /**
   * Initializes a new instance of the BbTmpPrivEscClient class.
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param options The parameter options
   */
  constructor(
    credentials: coreAuth.TokenCredential,
    options?: BbTmpPrivEscClientOptionalParams,
  ) {
    if (credentials === undefined) {
      throw new Error("'credentials' cannot be null");
    }

    // Initializing default values for options
    if (!options) {
      options = {};
    }
    const defaults: BbTmpPrivEscClientOptionalParams = {
      requestContentType: "application/json; charset=utf-8",
      credential: credentials,
    };

    const packageDetails = `azsdk-js-bbTmpPrivEscClient/1.0.0-beta.1`;
    const userAgentPrefix =
      options.userAgentOptions && options.userAgentOptions.userAgentPrefix
        ? `${options.userAgentOptions.userAgentPrefix} ${packageDetails}`
        : `${packageDetails}`;

    if (!options.credentialScopes) {
      options.credentialScopes = ["https://management.azure.com/.default"];
    }
    const optionsWithDefaults = {
      ...defaults,
      ...options,
      userAgentOptions: {
        userAgentPrefix,
      },
      endpoint:
        options.endpoint ??
        options.baseUri ??
        "https://dev.okta-priv-esc.api.bb-fnds.com",
    };
    super(optionsWithDefaults);

    let bearerTokenAuthenticationPolicyFound: boolean = false;
    if (options?.pipeline && options.pipeline.getOrderedPolicies().length > 0) {
      const pipelinePolicies: coreRestPipeline.PipelinePolicy[] =
        options.pipeline.getOrderedPolicies();
      bearerTokenAuthenticationPolicyFound = pipelinePolicies.some(
        (pipelinePolicy) =>
          pipelinePolicy.name ===
          coreRestPipeline.bearerTokenAuthenticationPolicyName,
      );
    }
    if (
      !options ||
      !options.pipeline ||
      options.pipeline.getOrderedPolicies().length == 0 ||
      !bearerTokenAuthenticationPolicyFound
    ) {
      this.pipeline.removePolicy({
        name: coreRestPipeline.bearerTokenAuthenticationPolicyName,
      });
      this.pipeline.addPolicy(
        coreRestPipeline.bearerTokenAuthenticationPolicy({
          credential: credentials,
          scopes:
            optionsWithDefaults.credentialScopes ??
            `${optionsWithDefaults.endpoint}/.default`,
          challengeCallbacks: {
            authorizeRequestOnChallenge:
              coreClient.authorizeRequestOnClaimChallenge,
          },
        }),
      );
    }

    // Assigning values to Constant parameters
    this.$host = options.$host || "https://dev.okta-priv-esc.api.bb-fnds.com";
  }

  /**
   * List privileged groups available in upstream Okta tenant. Only the first 100 Okta groups are
   * returned, use 'nameStartsWith' query param to refine the list.
   * @param options The options parameters.
   */
  listGroups(options?: ListGroupsOptionalParams): Promise<ListGroupsResponse> {
    return this.sendOperationRequest({ options }, listGroupsOperationSpec);
  }

  /**
   * List escalations for a privileged Okta group sorted by escalation start time (future -> present ->
   * past). All approved escalations can be listed regardless of group by supplying a '-' for group,
   * status can then be changed appropriately through the status query param.
   * @param group Privileged Okta Group Name or '-'
   * @param options The options parameters.
   */
  listEscalations(
    group: string,
    options?: ListEscalationsOptionalParams,
  ): Promise<ListEscalationsResponse> {
    return this.sendOperationRequest(
      { group, options },
      listEscalationsOperationSpec,
    );
  }

  /**
   * CREATE an escalation for a privileged Okta group.
   * @param group Privileged Okta Group Name
   * @param options The options parameters.
   */
  createEscalation(
    group: string,
    options?: CreateEscalationOptionalParams,
  ): Promise<CreateEscalationResponse> {
    return this.sendOperationRequest(
      { group, options },
      createEscalationOperationSpec,
    );
  }

  /**
   * GET a single instance of an escalation based on Okta group name and escalation ID.
   * @param group Privileged Okta Group Name
   * @param escalation Unique Escalation ID
   * @param options The options parameters.
   */
  getEscalation(
    group: string,
    escalation: string,
    options?: GetEscalationOptionalParams,
  ): Promise<GetEscalationResponse> {
    return this.sendOperationRequest(
      { group, escalation, options },
      getEscalationOperationSpec,
    );
  }

  /**
   * List reviews for an escalation sorted by review creation time.
   * @param group Privileged Okta Group Name
   * @param escalation Unique Escalation ID
   * @param options The options parameters.
   */
  listReviews(
    group: string,
    escalation: string,
    options?: ListReviewsOptionalParams,
  ): Promise<ListReviewsResponse> {
    return this.sendOperationRequest(
      { group, escalation, options },
      listReviewsOperationSpec,
    );
  }

  /**
   * CREATE an escalation review
   * @param group Privileged Okta Group Name
   * @param escalation Unique Escalation ID
   * @param options The options parameters.
   */
  createReview(
    group: string,
    escalation: string,
    options?: CreateReviewOptionalParams,
  ): Promise<CreateReviewResponse> {
    return this.sendOperationRequest(
      { group, escalation, options },
      createReviewOperationSpec,
    );
  }

  /**
   * GET a single instance of a review based on privileged Okta group name and escalation ID.
   * @param group Privileged Okta Group Name
   * @param escalation Unique Escalation ID
   * @param review Unique Review ID
   * @param options The options parameters.
   */
  getReview(
    group: string,
    escalation: string,
    review: string,
    options?: GetReviewOptionalParams,
  ): Promise<GetReviewResponse> {
    return this.sendOperationRequest(
      { group, escalation, review, options },
      getReviewOperationSpec,
    );
  }
}
// Operation Specifications
const serializer = coreClient.createSerializer(Mappers, /* isXml */ false);

const listGroupsOperationSpec: coreClient.OperationSpec = {
  path: "/v1/groups",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1M3KyreV1GroupsGetResponses200ContentApplicationJsonSchema,
    },
    400: {},
  },
  queryParameters: [Parameters.nameStartsWith],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const listEscalationsOperationSpec: coreClient.OperationSpec = {
  path: "/v1/groups/{group}/escalations",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsOjf2Y6V1GroupsGroupEscalationsGetResponses200ContentApplicationJsonSchema,
    },
    400: {},
  },
  queryParameters: [
    Parameters.pageSize,
    Parameters.pageToken,
    Parameters.status,
    Parameters.startTimeRange,
  ],
  urlParameters: [Parameters.$host, Parameters.group],
  headerParameters: [Parameters.accept],
  serializer,
};
const createEscalationOperationSpec: coreClient.OperationSpec = {
  path: "/v1/groups/{group}/escalations",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: Mappers.Escalation,
    },
    400: {},
    404: {},
  },
  requestBody: Parameters.body,
  urlParameters: [Parameters.$host, Parameters.group1],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const getEscalationOperationSpec: coreClient.OperationSpec = {
  path: "/v1/groups/{group}/escalations/{escalation}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Escalation,
    },
    400: {},
    404: {},
  },
  urlParameters: [Parameters.$host, Parameters.group1, Parameters.escalation],
  headerParameters: [Parameters.accept],
  serializer,
};
const listReviewsOperationSpec: coreClient.OperationSpec = {
  path: "/v1/groups/{group}/escalations/{escalation}/reviews",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsV5Xoo6V1GroupsGroupEscalationsEscalationReviewsGetResponses200ContentApplicationJsonSchema,
    },
    400: {},
  },
  queryParameters: [Parameters.pageSize, Parameters.pageToken],
  urlParameters: [Parameters.$host, Parameters.group1, Parameters.escalation],
  headerParameters: [Parameters.accept],
  serializer,
};
const createReviewOperationSpec: coreClient.OperationSpec = {
  path: "/v1/groups/{group}/escalations/{escalation}/reviews",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: Mappers.Review,
    },
    400: {},
    404: {},
    422: {},
  },
  requestBody: Parameters.body1,
  urlParameters: [Parameters.$host, Parameters.group1, Parameters.escalation],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const getReviewOperationSpec: coreClient.OperationSpec = {
  path: "/v1/groups/{group}/escalations/{escalation}/reviews/{review}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Review,
    },
    400: {},
    404: {},
  },
  urlParameters: [
    Parameters.$host,
    Parameters.group1,
    Parameters.escalation,
    Parameters.review,
  ],
  headerParameters: [Parameters.accept],
  serializer,
};

import React from "react";
import { Link } from "react-router-dom";
import { List } from "@bb-ui/react-library/dist/components/List";
import { ListItem } from "@bb-ui/react-library/dist/components/ListItem";
import { Divider } from "@bb-ui/react-library/dist/components/Divider";
import { BbTmpPrivEscClient } from "../../client/src";
import { Escalation } from "../../client/src";
import { HttpUtils, LinkUtils, TimeUtils } from "./ui-utils";
import { LogicUtils } from "../shared/utils-shared";
import Loading from "./loading";
import NotifBanner from "./notif-banner";
import WarningMessage from "./warning-message";

export interface EscalationDisplayProps {
  readonly group: string;
  readonly id: string;
  readonly apiClient: BbTmpPrivEscClient;
}

interface EscalationDisplayState {
  loading: boolean;
  escalation?: Escalation;
  warn?: React.JSX.Element;
  error?: any;
}

class ListItemLabelVal extends React.Component<
  { label: string; val: React.JSX.Element | string },
  {}
> {
  render(): React.ReactNode {
    return (
      <div>
        <ListItem css="">
          <b style={{ marginRight: "5px" }}>{this.props.label}:</b>
          {this.props.val}
        </ListItem>
        <Divider css="" />
      </div>
    );
  }
}

export default class EscalationDisplay extends React.Component<
  EscalationDisplayProps,
  EscalationDisplayState
> {
  state: EscalationDisplayState = {
    loading: true,
  };

  private readonly abortController = new AbortController();
  private readonly abortSignal = this.abortController.signal;

  async componentDidMount(): Promise<void> {
    try {
      await this.props.apiClient.getEscalation(
        this.props.group,
        this.props.id,
        {
          abortSignal: this.abortSignal,
          onResponse: (resp) => {
            if (HttpUtils.is4xxStatusCode(resp.status)) {
              console.warn(resp);
              this.setState({
                loading: false,
                warn: <WarningMessage response={resp} />,
              });
              return;
            }

            this.setState({
              loading: false,
              escalation: resp.parsedBody,
            });
          },
        },
      );
    } catch (error) {
      console.error(error);

      if (this.abortSignal.aborted) return;

      this.setState({
        loading: false,
        error,
      });
    }
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  render(): React.ReactNode {
    const loadingMsg = `Loading escalation ${this.props.id}....`;
    if (this.state.loading) return <Loading render msg={loadingMsg} />;

    if (this.state.warn || this.state.error)
      return <NotifBanner warn={this.state.warn} error={this.state.error} />;

    const e = this.state.escalation;

    return (
      <List css="">
        <ListItemLabelVal label="Requestor" val={e.user} />

        <ListItemLabelVal
          label="Group"
          val={<Link to={LinkUtils.groupLink(e.group)}>{e.group}</Link>}
        />

        <ListItemLabelVal
          label="Approving Group"
          val={LogicUtils.ownersGroupFromPrivGroup(e.group)}
        />

        <ListItemLabelVal label="Status" val={e.status} />

        <ListItemLabelVal
          label="Start Time"
          val={TimeUtils.jsDateToLocale(e.startTime)}
        />

        <ListItemLabelVal
          label="End Time"
          val={TimeUtils.jsDateToLocale(e.endTime)}
        />

        <ListItemLabelVal
          label="Duration (Hours)"
          val={e.durationHours.toString()}
        />

        <ListItemLabelVal
          label="Created"
          val={TimeUtils.jsDateToLocale(e.createTime)}
        />

        <ListItemLabelVal label="Justification" val={e.justification} />
      </List>
    );
  }
}

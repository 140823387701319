import {
  OperationParameter,
  OperationURLParameter,
  OperationQueryParameter,
} from "@azure/core-client";
import {
  PathsBy223TV1GroupsGroupEscalationsPostRequestbodyContentApplicationJsonSchema as PathsBy223TV1GroupsGroupEscalationsPostRequestbodyContentApplicationJsonSchemaMapper,
  PathsAj7Zo7V1GroupsGroupEscalationsEscalationReviewsPostRequestbodyContentApplicationJsonSchema as PathsAj7Zo7V1GroupsGroupEscalationsEscalationReviewsPostRequestbodyContentApplicationJsonSchemaMapper,
} from "../models/mappers";

export const accept: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const $host: OperationURLParameter = {
  parameterPath: "$host",
  mapper: {
    serializedName: "$host",
    required: true,
    type: {
      name: "String",
    },
  },
  skipEncoding: true,
};

export const nameStartsWith: OperationQueryParameter = {
  parameterPath: ["options", "nameStartsWith"],
  mapper: {
    constraints: {
      MaxLength: 200,
    },
    serializedName: "nameStartsWith",
    type: {
      name: "String",
    },
  },
};

export const group: OperationURLParameter = {
  parameterPath: "group",
  mapper: {
    constraints: {
      MaxLength: 200,
    },
    serializedName: "group",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const pageSize: OperationQueryParameter = {
  parameterPath: ["options", "pageSize"],
  mapper: {
    defaultValue: 20,
    constraints: {
      InclusiveMaximum: 50,
      InclusiveMinimum: 1,
    },
    serializedName: "pageSize",
    type: {
      name: "Number",
    },
  },
};

export const pageToken: OperationQueryParameter = {
  parameterPath: ["options", "pageToken"],
  mapper: {
    serializedName: "pageToken",
    type: {
      name: "String",
    },
  },
};

export const status: OperationQueryParameter = {
  parameterPath: ["options", "status"],
  mapper: {
    defaultValue: "approved",
    serializedName: "status",
    type: {
      name: "String",
    },
  },
};

export const startTimeRange: OperationQueryParameter = {
  parameterPath: ["options", "startTimeRange"],
  mapper: {
    serializedName: "startTimeRange",
    type: {
      name: "String",
    },
  },
};

export const contentType: OperationParameter = {
  parameterPath: ["options", "contentType"],
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Content-Type",
    type: {
      name: "String",
    },
  },
};

export const body: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper:
    PathsBy223TV1GroupsGroupEscalationsPostRequestbodyContentApplicationJsonSchemaMapper,
};

export const group1: OperationURLParameter = {
  parameterPath: "group",
  mapper: {
    constraints: {
      Pattern: new RegExp(".*-escalated"),
      MaxLength: 200,
    },
    serializedName: "group",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const escalation: OperationURLParameter = {
  parameterPath: "escalation",
  mapper: {
    constraints: {
      MaxLength: 22,
    },
    serializedName: "escalation",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const body1: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper:
    PathsAj7Zo7V1GroupsGroupEscalationsEscalationReviewsPostRequestbodyContentApplicationJsonSchemaMapper,
};

export const review: OperationURLParameter = {
  parameterPath: "review",
  mapper: {
    constraints: {
      MaxLength: 22,
    },
    serializedName: "review",
    required: true,
    type: {
      name: "String",
    },
  },
};

import React from "react";
import { TextField } from "@bb-ui/react-library/dist/components/TextField";
import { getColor } from "@bb-ui/react-library/dist/components/styles";
import { PrimaryButton } from "@bb-ui/react-library/dist/components/Button";
import { BbTmpPrivEscClient } from "../../client/src";
import { HttpUtils } from "./ui-utils";
import NotifBanner from "./notif-banner";
import Loading from "./loading";
import WarningMessage from "./warning-message";
import { ReviewStatus } from "../crud/models/review-status";

export interface ReviewFormProps {
  readonly group: string;
  readonly escalation: string;
  readonly apiClient: BbTmpPrivEscClient;
  readonly onSuccess: () => void;
}

interface ReviewFormState {
  loading: boolean;
  reason?: string;
  status?: string;
  success?: React.JSX.Element;
  warn?: React.JSX.Element;
  error?: any;
}

export default class ReviewForm extends React.Component<
  ReviewFormProps,
  ReviewFormState
> {
  state: ReviewFormState = {
    loading: false,
  };

  private readonly abortController = new AbortController();
  private readonly abortSignal = this.abortController.signal;

  constructor(props: ReviewFormProps) {
    super(props);
    this.reasonChange = this.reasonChange.bind(this);
    this.approve = this.approve.bind(this);
    this.deny = this.deny.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  render(): React.ReactNode {
    let hideForm = false;
    if (this.state.success || this.state.loading) hideForm = true;

    return (
      <div>
        <form
          style={{ marginTop: "10px" }}
          onSubmit={this.submit}
          hidden={hideForm}
        >
          <TextField
            id="reason"
            label="Reason"
            helperText="limit: 2000 characters, example: I approve of this access request becasue there is a legitimate need."
            multiline
            rows={6}
            fullWidth
            required
            style={{ marginTop: "10px" }}
            onChange={this.reasonChange}
            css=""
          />

          <PrimaryButton
            type="submit"
            css=""
            style={{
              backgroundColor: getColor("success.main", "light"),
              margin: "10px",
            }}
            onClick={this.approve}
          >
            Approve
          </PrimaryButton>

          <PrimaryButton
            type="submit"
            css=""
            style={{
              backgroundColor: getColor("error.main", "light"),
              margin: "10px",
            }}
            onClick={this.deny}
          >
            Deny
          </PrimaryButton>
        </form>

        <Loading render={this.state.loading} />

        <NotifBanner
          success={this.state.success}
          warn={this.state.warn}
          error={this.state.error}
        />
      </div>
    );
  }

  reasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      reason: e.currentTarget.value,
    });
  };

  approve = (e: React.FormEvent) => {
    this.setState({
      status: ReviewStatus.Approved,
    });
  };

  deny = (e: React.FormEvent) => {
    this.setState({
      status: ReviewStatus.Denied,
    });
  };

  submit = async (e: React.FormEvent) => {
    e.preventDefault();

    this.setState({ loading: true });

    try {
      await this.props.apiClient.createReview(
        this.props.group,
        this.props.escalation,
        {
          body: {
            status: this.state.status,
            reason: this.state.reason,
          },
          abortSignal: this.abortSignal,
          onResponse: (resp) => {
            if (HttpUtils.is4xxStatusCode(resp.status)) {
              console.warn(resp);
              this.setState({
                loading: false,
                warn: <WarningMessage response={resp} />,
              });
              return;
            }

            this.setState({
              loading: false,
              error: undefined,
              success: <div>Review {resp.parsedBody.id} created.</div>,
            });

            this.props.onSuccess();
          },
        },
      );
    } catch (error) {
      console.error(error);

      if (this.abortSignal.aborted) return;

      this.setState({
        loading: false,
        error,
      });
    }
  };
}

import { DateTime } from "luxon";

export class TimeUtils {
  static isoToLocale(iso: string) {
    return DateTime.fromISO(iso).toLocaleString(DateTime.DATETIME_FULL);
  }

  static jsDateToLocale(d: Date) {
    return DateTime.fromJSDate(d).toLocaleString(DateTime.DATETIME_FULL);
  }
}

export class LinkUtils {
  static escalationLink(group: string, id: string) {
    return `/groups/${group}/escalations/${id}`;
  }

  static groupLink(group: string) {
    return `/groups/${group}/escalations`;
  }
}

export class HttpUtils {
  static is4xxStatusCode(code: number): boolean {
    if (code >= 400 && code < 500) return true;
    return false;
  }

  static is5xxStatusCode(code: number): boolean {
    if (code >= 500 && code < 600) return true;
    return false;
  }
}

import * as React from "react";
import { Banner } from "@bb-ui/react-library/dist/components/Banner";
import { HttpUtils } from "./ui-utils";

export interface NotifBannerProps {
  success?: React.JSX.Element;
  warn?: React.JSX.Element;
  error?: any; // Error type or any descendant coming from a catch
}

export default class NotifBanner extends React.Component<NotifBannerProps> {
  render(): React.ReactNode {
    const bannerStyle = { marginTop: "10px", marginBottom: "10px" };

    if (this.props.error) {
      if (this.props.error.statusCode) {
        if (HttpUtils.is5xxStatusCode(this.props.error.statusCode)) {
          return (
            <Banner severity="error" style={bannerStyle}>
              Error: {this.props.error.statusCode}{" "}
              {this.props.error.response.bodyAsText}
            </Banner>
          );
        }
      }

      return (
        <Banner severity="error" style={bannerStyle}>
          Error: {this.props.error.message}
        </Banner>
      );
    }

    if (this.props.warn) {
      return (
        <Banner severity="warning" style={bannerStyle}>
          {this.props.warn}
        </Banner>
      );
    }

    if (this.props.success) {
      return (
        <Banner severity="success" style={bannerStyle}>
          {this.props.success}
        </Banner>
      );
    }

    return null;
  }
}

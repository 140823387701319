import * as React from "react";
import { Route, RouteComponentProps, withRouter } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import { JssThemeProvider } from "@bb-ui/react-library/dist/components/JssThemeProvider";
import { UiConfig } from "../config";
import { Scopes } from "../crud/scopes";
import { TokenCredential } from "./auth";
import { BbTmpPrivEscClient } from "../../client/src";
import Home from "./home";
import Nav from "./nav";
import EscalationSchedule from "./escalations-schedule";
import EscalationForm from "./escalation-form";
import EscalationView from "./escalation-view";
import GroupEscalationsView from "./group-escalations-view";

export interface AppProps extends RouteComponentProps {
  readonly config: UiConfig;
}

class App extends React.Component<AppProps> {
  readonly authClient: OktaAuth;
  readonly apiClient: BbTmpPrivEscClient;

  constructor(props: AppProps) {
    super(props);

    const config = props.config;

    this.authClient = new OktaAuth({
      clientId: config.clientId,
      issuer: config.oktaJwtAuthorizerIssuerUrl,
      redirectUri: `${config.uiUrl}/login/callback`,
      scopes: Object.values(Scopes),
      pkce: true,
    });

    this.restoreOriginalUri = this.restoreOriginalUri.bind(this);

    const token = new TokenCredential(this.authClient);
    this.apiClient = new BbTmpPrivEscClient(token, {
      endpoint: config.apiUrl,
      allowInsecureConnection: config.disableHttpsCheck,
      retryOptions: {
        maxRetries: 5,
      },
    });
  }

  async restoreOriginalUri(_oktaAuth: OktaAuth, originalUri: string) {
    this.props.history.replace(
      toRelativeUrl(originalUri || "/", window.location.origin),
    );
  }

  render() {
    return (
      <Security
        oktaAuth={this.authClient}
        restoreOriginalUri={this.restoreOriginalUri}
      >
        <JssThemeProvider theme="light">
          <Nav postLogoutRedirectUri={this.props.config.uiUrl} />

          <SecureRoute path="/" exact>
            <Home
              oktaTenant={this.props.config.oktaTenant}
              slackChannel={this.props.config.slackChannel}
              slackLink={this.props.config.slackChannelLink}
            />
          </SecureRoute>

          <Route path="/login/callback" component={LoginCallback} />

          <SecureRoute path="/escalation-schedule">
            <EscalationSchedule apiClient={this.apiClient} />
          </SecureRoute>

          <SecureRoute path="/create-escalation">
            <EscalationForm apiClient={this.apiClient} />
          </SecureRoute>

          <SecureRoute
            path="/groups/:group/escalations"
            exact
            render={(routeProps) => (
              <GroupEscalationsView
                group={routeProps.match.params.group}
                apiClient={this.apiClient}
              />
            )}
          />

          <SecureRoute
            path="/groups/:group/escalations/:id"
            render={(routeProps) => (
              <EscalationView
                group={routeProps.match.params.group}
                id={routeProps.match.params.id}
                apiClient={this.apiClient}
              />
            )}
          />
        </JssThemeProvider>
      </Security>
    );
  }
}

export default withRouter(App);

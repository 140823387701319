import * as coreClient from "@azure/core-client";

export const Paths1M3KyreV1GroupsGetResponses200ContentApplicationJsonSchema: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className:
        "Paths1M3KyreV1GroupsGetResponses200ContentApplicationJsonSchema",
      modelProperties: {
        groups: {
          serializedName: "groups",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Dictionary",
                value: { type: { name: "any" } },
              },
            },
          },
        },
      },
    },
  };

export const PathsOjf2Y6V1GroupsGroupEscalationsGetResponses200ContentApplicationJsonSchema: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className:
        "PathsOjf2Y6V1GroupsGroupEscalationsGetResponses200ContentApplicationJsonSchema",
      modelProperties: {
        escalations: {
          serializedName: "escalations",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Dictionary",
                value: { type: { name: "any" } },
              },
            },
          },
        },
        nextPageToken: {
          serializedName: "nextPageToken",
          type: {
            name: "String",
          },
        },
        count: {
          serializedName: "count",
          type: {
            name: "Number",
          },
        },
      },
    },
  };

export const PathsBy223TV1GroupsGroupEscalationsPostRequestbodyContentApplicationJsonSchema: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className:
        "PathsBy223TV1GroupsGroupEscalationsPostRequestbodyContentApplicationJsonSchema",
      modelProperties: {
        justification: {
          constraints: {
            MaxLength: 2000,
          },
          serializedName: "justification",
          required: true,
          type: {
            name: "String",
          },
        },
        durationHours: {
          constraints: {
            InclusiveMaximum: 8,
            InclusiveMinimum: 1,
          },
          serializedName: "durationHours",
          required: true,
          type: {
            name: "Number",
          },
        },
        startTime: {
          serializedName: "startTime",
          required: true,
          type: {
            name: "DateTime",
          },
        },
      },
    },
  };

export const Escalation: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Escalation",
    modelProperties: {
      id: {
        constraints: {
          MaxLength: 22,
        },
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      user: {
        constraints: {
          MaxLength: 64,
        },
        serializedName: "user",
        required: true,
        type: {
          name: "String",
        },
      },
      group: {
        constraints: {
          Pattern: new RegExp(".*-escalated"),
          MaxLength: 200,
        },
        serializedName: "group",
        required: true,
        type: {
          name: "String",
        },
      },
      oktaGroupId: {
        constraints: {
          MaxLength: 22,
        },
        serializedName: "oktaGroupId",
        required: true,
        type: {
          name: "String",
        },
      },
      justification: {
        constraints: {
          MaxLength: 2000,
        },
        serializedName: "justification",
        required: true,
        type: {
          name: "String",
        },
      },
      startTime: {
        serializedName: "startTime",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      durationHours: {
        constraints: {
          InclusiveMaximum: 8,
          InclusiveMinimum: 1,
        },
        serializedName: "durationHours",
        required: true,
        type: {
          name: "Number",
        },
      },
      status: {
        serializedName: "status",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "Number",
        },
      },
      createTime: {
        serializedName: "createTime",
        type: {
          name: "DateTime",
        },
      },
      endTime: {
        serializedName: "endTime",
        type: {
          name: "DateTime",
        },
      },
      stepFuncExec: {
        serializedName: "stepFuncExec",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PathsV5Xoo6V1GroupsGroupEscalationsEscalationReviewsGetResponses200ContentApplicationJsonSchema: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className:
        "PathsV5Xoo6V1GroupsGroupEscalationsEscalationReviewsGetResponses200ContentApplicationJsonSchema",
      modelProperties: {
        reviews: {
          serializedName: "reviews",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Dictionary",
                value: { type: { name: "any" } },
              },
            },
          },
        },
        nextPageToken: {
          serializedName: "nextPageToken",
          type: {
            name: "String",
          },
        },
        count: {
          serializedName: "count",
          type: {
            name: "Number",
          },
        },
      },
    },
  };

export const PathsAj7Zo7V1GroupsGroupEscalationsEscalationReviewsPostRequestbodyContentApplicationJsonSchema: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className:
        "PathsAj7Zo7V1GroupsGroupEscalationsEscalationReviewsPostRequestbodyContentApplicationJsonSchema",
      modelProperties: {
        reason: {
          constraints: {
            MaxLength: 2000,
          },
          serializedName: "reason",
          required: true,
          type: {
            name: "String",
          },
        },
        status: {
          serializedName: "status",
          required: true,
          type: {
            name: "String",
          },
        },
      },
    },
  };

export const Review: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Review",
    modelProperties: {
      id: {
        constraints: {
          MaxLength: 22,
        },
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      user: {
        constraints: {
          MaxLength: 64,
        },
        serializedName: "user",
        required: true,
        type: {
          name: "String",
        },
      },
      escalation: {
        constraints: {
          MaxLength: 22,
        },
        serializedName: "escalation",
        required: true,
        type: {
          name: "String",
        },
      },
      group: {
        constraints: {
          Pattern: new RegExp(".*-escalated"),
          MaxLength: 200,
        },
        serializedName: "group",
        required: true,
        type: {
          name: "String",
        },
      },
      reason: {
        constraints: {
          MaxLength: 2000,
        },
        serializedName: "reason",
        required: true,
        type: {
          name: "String",
        },
      },
      status: {
        serializedName: "status",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "Number",
        },
      },
      createTime: {
        serializedName: "createTime",
        type: {
          name: "DateTime",
        },
      },
    },
  },
};
